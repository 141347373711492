import React from 'react';
import './StringList.scss';

import close_Icon from '../../../assets/icons/close_black.svg';

export default function StringList({ titleText, addButtonText, listElements, setListElements, selectItems = null }) {
    function handleAddElement() {
        /* Add new element to the list */
        setListElements([...listElements, ""]);
    }

    function handleElementsChange(index, newValue) {
        /* Change the element at index */
        setListElements(
            (listElements ?? []).map((element, attrIndex) => {
                if (index === attrIndex) {
                    return newValue;
                }
                return element;
            })
        );
    }

    function handleRemoveElement(index) {
        /* Remove element at index */
        setListElements(listElements.filter((_, i) => i !== index));
    }

    return (
        <div className="string-list">
            <label>{titleText}:</label>
            <div className="string-list-fields">
                {(listElements ?? []).map((element, index) => (
                    <div className="string-list-field">
                        {selectItems ?
                            <select key={index} value={element} onChange={(e) => handleElementsChange(index, e.target.value)}>
                                {Object.entries(selectItems ?? {}).map(([key, value]) => (
                                    <option key={key} value={key}>{value}</option>
                                ))}
                            </select>
                            :
                            <input type="text" key={index} value={element} onChange={(e) => handleElementsChange(index, e.target.value)} />
                        }
                        <button className="light-button" onClick={() => handleRemoveElement(index)}>
                            <img src={close_Icon} alt="close" />
                        </button>
                    </div>
                ))}
                <button
                    className="settings-modal-button light-button"
                    onClick={handleAddElement}>{addButtonText}
                </button>
            </div>
        </div>
    )
}